import React from 'react'
import Helmet from 'react-helmet'
import PageHeader from 'components/PageHeader'
import { Grid, Row, Col } from 'react-flexbox-grid'
import { Paragraph } from 'components/Typography'
import styles from './index.module.less'
import image1 from 'images/project-11a.png'
import image2 from 'images/project-11b.png'
import Layout from 'components/Layout'

const Page = () => (
  <Layout>
    <Helmet
      title="Exterior upgrade and landscaping"
      meta={[
        {
          name: 'description',
          content:
            'For a solo mother and her 11-year-old son, a home and landscape makeover has made the world of difference.',
        },
      ]}
    >
      <html lang="en" />
    </Helmet>
    <PageHeader
      title="Exterior upgrade and landscaping"
      intro="For a solo mother and her 11-year-old son, a home and landscape makeover has made the world of difference."
    />
    <Grid fluid>
      <Row>
        <Col sm={8} className={styles.projectText}>
          <Paragraph>
            Early in 2020, the Ryhaven trust was asked to assist a Titahi Bay
            homeowner undergoing chemotherapy treatment.
          </Paragraph>
          <Paragraph>
            The trust got to work tidying up the section, a mission which
            immediately lifted the homeowner&rsquo;s spirit. Volunteer Nick and
            his team managed to fill three skips.
          </Paragraph>
          <Paragraph>
            The crew then landscaped the backyard, laying eight metres of
            topsoil, and sowing new lawn to make the space more manageable for
            the family.
          </Paragraph>
          <Paragraph>
            Ryhaven&rsquo;s painter, Paul, gave the interior rooms a fresh coat.
            Bernard, our builder, repaired the bathroom: plastered the walls,
            installed extraction fans and laid vinyl flooring. He also upgraded
            existing storage space and repaired interior doors. Thanks to{' '}
            <a href="https://www.nme.co.nz">NME</a> for their electrical help
            too.
          </Paragraph>
          <Paragraph>
            The final touch is an exterior paint, which the team can begin once
            the weather improves.
          </Paragraph>
          <Paragraph>
            Ryhaven have done what they can to ease the burden on a mother and
            son experiencing a difficult time in their lives.
          </Paragraph>
        </Col>
        <Col sm={4} className={styles.projectImages}>
          <img className={styles.image} src={image1} alt="" />
          <img className={styles.image} src={image2} alt="" />
        </Col>
      </Row>
    </Grid>
  </Layout>
)

export default Page
